import { getDateNoTime } from '@assets/js/dateUtils';
import helper from '@assets/js/helper';
import Vue from 'vue';

/**
 * 出运状态返回值
 */
const formatterShipstat = scon_shipstat => {
  if (scon_shipstat === 0) {
    return '未出运';
  } else if (scon_shipstat === 1) {
    return '已出运';
  } else if (scon_shipstat === 2) {
    return '部分出运';
  }
};
const formatterCreateState = create_status => {
  if (create_status === 0) {
    return '未生成';
  } else if (create_status === 1) {
    return '部分生成';
  } else if (create_status === 2) {
    return '已生成';
  }
};
export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    prop: 'scon_shipstat',
    label: '出运状态',
    itemType: 'select',
    options: [
      { value: 0, label: '未出运' },
      { value: 1, label: '已出运' },
      { value: 2, label: '部分出运' }
    ],
    labelWidth: 120,
    formatter: val => formatterShipstat(val)
  },
  {
    prop: 'create_status',
    label: '成品采购合同状态',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => formatterCreateState(val)
  },
  { prop: 'scon_no', label: '销售合同号', itemType: 'input', sortable: false, labelWidth: 120 },
  { prop: 'cust_abbr', label: '客户简称', itemType: 'input', labelWidth: 120 },
  { prop: 'cust_babbr', label: '最终客户', itemType: 'input', sortable: false, labelWidth: 120 },
  { prop: 'scon_cust_no', label: '客户订单号', itemType: 'input', sortable: false, labelWidth: 120 },
  { prop: 'scon_shdate', label: '装运日期', itemType: 'date', formatter: val => getDateNoTime(val, true), labelWidth: 120 },
  {
    prop: 'scon_prtotal',
    label: '货款金额',
    itemType: 'input',
    align: 'right',
    input: false,
    formatter: val => helper.haveFour(val)
  },
  {
    prop: 'scon_fetotal',
    label: '费用金额',
    itemType: 'input',
    align: 'right',
    input: false,
    sortable: false,
    formatter: val => helper.haveFour(val)
  },
  {
    prop: 'scon_total',
    label: '合同金额',
    itemType: 'input',
    align: 'right',
    input: false,
    sortable: false,
    formatter: val => helper.haveFour(val)
  },
  { prop: 'cust_payway', label: '付款方式', itemType: 'select', options: [] },
  { prop: 'cust_dport', label: '目的港', itemType: 'select', options: [], sortable: false, labelWidth: 120 },
  { prop: 'cptt_aname_en', label: '公司抬头', itemType: 'input', overflowTooltip: true, sortable: false, labelWidth: 120 },
  { prop: 'cust_stff_name', label: '销售经办人', itemType: 'input', sortable: false, labelWidth: 120 },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: 120, sortable: false },
  {
    prop: 'dept_name',
    label: '录入部门',
    itemType: 'select',
    options: [],
    labelWidth: 120,
    sortable: false
  },
  { prop: 'create_time', label: '录入时间', itemType: 'date', formatter: val => getDateNoTime(val, true), labelWidth: 120 },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const sconProdProperties = [
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '新我司货号',
    prop: 'new_prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '开票品名',
    prop: 'prod_inv_name',
    itemType: 'input',
    visible: false,
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false, maxLength: 10, copyAndPaste: true }
  },
  {
    label: '货号后缀',
    prop: 'prod_poststfix',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput', maxLength: 100 }
  },
  {
    label: '英文描述',
    prop: 'prod_espec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput' }
  },
  {
    label: '产品类型',
    prop: 'prod_ilk',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'newSelect',
      options: {
        0: { value: 0, label: '新款' },
        1: { value: 1, label: '修改' },
        2: { value: 2, label: '翻单' }
      },
      disabled: true
    }
  },
  {
    label: '成品单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '出运状态',
    prop: 'prod_shipstat',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true },
    formatter: val => (val === 0 ? '未出运' : val === 1 ? '已出运' : val === 2 ? '部分出运' : '暂无')
  },
  {
    label: '成品数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false, required: true }
  },
  {
    label: '销售价',
    prop: 'prod_usd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  {
    label: '金额(美元)',
    prop: 'prod_total_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '内包装数量',
    prop: 'prod_qpb',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  { label: '外包装数量', prop: 'prod_qpc', itemType: 'input', input: false, sortable: false, labelWidth: 180, subItem: { type: 'input' } },
  {
    label: '外包装单位',
    prop: 'prod_box_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  {
    label: '箱数',
    prop: 'prod_box_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '长(CM)',
    prop: 'prod_boxl',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '宽(CM)',
    prop: 'prod_boxw',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '高(CM)',
    prop: 'prod_boxh',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '外箱毛重',
    prop: 'prod_gweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '总毛重',
    prop: 'total_gross_weight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '外箱净重',
    prop: 'prod_nweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '总净重',
    prop: 'total_net_weight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '外箱体积',
    prop: 'prod_vol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '总体积',
    prop: 'total_volume',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '产品条形码',
    prop: 'prod_bar',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '内包装条形码',
    prop: 'prod_inbar',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  {
    label: '外箱条形码',
    prop: 'prod_outbar',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  {
    label: '美金报价',
    prop: 'prod_usd_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '对应外销',
    prop: 'scon_stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false },
    needOtherColumn: true,
    formatter: row => row.cust_stff_name
  }
];
export const sconPartProperties = [
  {
    label: '供应商简称',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'newSelect', disabled: false, options: {} }
  },
  {
    label: '指定供应商',
    prop: 'designate_supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    visible: false,
    subItem: { type: 'newSelect', disabled: false, options: {} }
  },
  {
    label: '是否主商品',
    prop: 'prod_mainsup',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '货号后缀',
    prop: 'prod_poststfix',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput', disabled: true }
  },
  {
    label: '部件名称',
    prop: 'prod_partno',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  /*  {
    label: '中文规格',
    prop: 'prod_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput', disabled: false }
  },*/
  {
    label: '中文描述',
    prop: 'prod_desc',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput', disabled: false }
  },
  {
    label: '部件单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  { label: '用量', prop: 'prod_use', itemType: 'input', input: false, sortable: false, labelWidth: 180, subItem: { type: 'input' } },
  {
    label: '预计数量',
    prop: 'scon_part_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '实际数量',
    prop: 'scon_part_num_reality',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '已申购数量',
    prop: 'scon_part_applied',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '需申购数量',
    prop: 'scon_part_apply',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '采购单价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '采购金额',
    prop: 'total_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '是否统计',
    prop: 'statistics',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    visible: Vue.prototype.$cookies.get('userInfo').dept_id === 28,
    subItem: { type: 'input', disabled: true },
    formatter: val => (val === 0 ? '否' : val === 1 ? '是' : '暂无')
  },
  {
    label: '采购合同状态',
    prop: 'create_status',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    fixed: 'right',
    subItem: { type: 'input', disabled: true }
  }
];
export const sconMtrbProperties = [
  {
    label: '材料编号',
    prop: 'mtrb_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '客户货号',
    prop: 'cust_catalog_number',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '中文品名',
    prop: 'mtrb_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput', disabled: true, maxLength: 100 }
  },
  {
    label: '规格',
    prop: 'mtrb_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'popoverInput', disabled: false, maxLength: 500 }
  },
  {
    label: '单位',
    prop: 'mtrb_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '耗量',
    prop: 'prod_mtrb_use',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  { label: '基数', prop: 'prod_mtrb_per', itemType: 'input', input: false, sortable: false, labelWidth: 180, subItem: { type: 'input' } },
  {
    label: '单耗比',
    prop: 'prod_mtrb_peruse',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '预计数量',
    prop: 'scon_mtrb_num_predict',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '实际数量',
    prop: 'scon_mtrb_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '供应商简称',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'newSelect', options: {} }
  },
  {
    label: '收货工厂',
    prop: 'supp_aid',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { disabled: true, type: 'newSelect', options: {} }
  },
  {
    label: '成品合同号',
    prop: 'podr_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    needOtherColumn: true,
    formatter: row => row.podr_no,
    subItem: {
      type: 'select',
      dynamicOptions: row => {
        if (row.list) {
          row.list.forEach(x => (x.value = Number(x.value)));
          return row.list;
        }
        return [];
      },
      change: (val, row, options) => {
        if (options) {
          let temp = options.find(x => x.value === val);
          row.podr_no = temp.label;
          row.supp_aid = Number(temp.permValue);
        } else {
          row.podr_no = null;
          row.supp_aid = null;
        }
      }
    }
  },
  {
    label: '已申购数量',
    prop: 'scon_mtrb_applied',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '需申购数量',
    prop: 'scon_mtrb_apply',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '采购单价',
    prop: 'prod_mtrb_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input' }
  },
  {
    label: '采购金额(元)',
    prop: 'mtrb_total_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '采购合同状态',
    prop: 'modr_status',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    fixed: 'right',
    subItem: { type: 'input', disabled: true }
  }
];

export const sconCatnipProperties = [
  {
    label: '猫草种类',
    prop: 'catnip_type',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '英文名称',
    prop: 'mtrb_ename',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '用量',
    prop: 'catnip_dosage',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: false }
  },
  {
    label: '数量',
    prop: 'catnip_num',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 'auto'
  },
  {
    label: '单位',
    prop: 'mtrb_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 'auto',
    subItem: { type: 'input', disabled: false, options: {} }
  },
  {
    label: '供应商简称',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { type: 'newSelect', disabled: false, options: {} }
  }
];

export const newWeeklyPOReportProperties = [
  {
    label: 'Product Type',
    prop: 'prod_type',
    itemType: 'select',
    options: [
      { value: 1, label: 'Classic' },
      { value: 2, label: 'SupperChewer' },
      { value: 3, label: 'Home' }
    ],
    input: true,
    sortable: false,
    labelWidth: 140,
    formatter: val => (val === 1 ? 'Classic' : val === 2 ? 'SupperChewer' : val === 3 ? 'Home' : '暂无')
  },
  { label: 'Channel', prop: 'cust_abbr', itemType: 'select', options: [], input: true, sortable: false, labelWidth: 140 },
  { label: 'Month / Retailer', prop: 'scon_series_name', itemType: 'input', input: true, sortable: false, labelWidth: 140 },
  { label: 'PO#', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 140 },
  { label: 'SKU#', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 140 },
  { label: 'Description', prop: 'prod_ename', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: 'PO Qty', prop: 'prod_num', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: 'Inner Case', prop: 'inner_case', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: 'Factory', prop: 'supp_ename', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  {
    label: 'Original PO Ship Date',
    prop: 'scon_shdate',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 200,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: 'Materials Received? Y/N',
    prop: 'materials_received',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 200,
    subItem: {
      type: 'select',
      options: [
        { label: 'Y', value: 'Y' },
        { label: 'N', value: 'N' }
      ]
    }
  },
  {
    label: 'Current Stage of Production',
    prop: 'PlushAndNonePlush',
    needSearch: false,
    labelWidth: 300
  },
  {
    label: 'Factory Ready Date',
    prop: 'factory_ready_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 210,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      type: 'date'
    }
  },
  {
    label: 'Est PSI Date',
    prop: 'inspection_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: 'Actual PSI Date',
    prop: 'reality_inspection_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      type: 'date'
    }
  },
  {
    label: 'Est Lab Testing Date',
    prop: 'expect_test_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 200,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: 'Lab Results Received',
    prop: 'reality_test_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 200,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      type: 'date'
    }
  },
  {
    label: 'Est Cargo Booking Date',
    prop: 'expect_booking_space_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 200,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: 'Actual Cargo Booking Date',
    prop: 'booking_space_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 210,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      type: 'date'
    }
  },
  {
    label: 'Confirmed CRD form Forwarder',
    prop: 'tran_spdate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 240,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      type: 'date'
    }
  },
  {
    label: 'Remarks',
    prop: 'statistical_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 240,
    subItem: { type: 'popoverInput' }
  }
  // {
  //   label: 'Operation',
  //   prop: 'operation',
  //   itemType: 'input',
  //   input: false,
  //   fixed: 'right',
  //   sortable: false,
  //   labelWidth: 90
  // }
];
export const barkOrdersProperties = [
  { label: 'Theme', prop: 'scon_series_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: 'Image',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'image', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  { label: 'Description', prop: 'prod_ename', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: 'SKU#', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: 'Qty.', prop: 'prod_num', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: 'Price', prop: 'prod_usd', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: 'Amount', prop: 'prod_total_price', itemType: 'input', input: false, sortable: true, widthAuto: true },
  {
    label: 'ETD',
    prop: 'scon_shdate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: 'Payment Date',
    prop: 'scon_ppdate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  }
];
export const collectionContractTableProperties = [
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  { prop: 'prod_no', label: '我司货号', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { prop: 'prod_cust_no', label: '客户货号', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { prop: 'prod_ename', label: '英文名称', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { prop: 'prod_espec', label: '英文规格', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { prop: 'scon_part_num_reality', label: '数量', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    prop: 'supp_id',
    label: '实际供应商',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'select',
      options: [],
      disabled: true
    }
  },
  {
    prop: 'designate_supp_id',
    label: '指定供应商',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'select',
      options: [],
      disabled: true
    }
  }
];
export const categorySalesStatisticsTableProperties = [
  {
    label: '品类',
    prop: 'prod_category',
    itemType: 'slot',
    input: true,
    sortable: false,
    labelWidth: 160
  },
  {
    label: '一月',
    prop: 'jan',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '二月',
    prop: 'feb',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '三月',
    prop: 'mar',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '四月',
    prop: 'apr',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '五月',
    prop: 'may',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '六月',
    prop: 'june',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '七月',
    prop: 'july',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '八月',
    prop: 'aug',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '九月',
    prop: 'sept',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十月',
    prop: 'oct',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十一月',
    prop: 'nov',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十二月',
    prop: 'dece',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140,
    align: 'right'
  }
];
